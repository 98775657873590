import { UpdatePasswordType } from '@pages/Settings/UpdatePassword';
import axiosInstance from '@services/axios';
import { LoginDataType, SignupDataType } from 'types/services';

export const register = (data: Omit<SignupDataType, 'confirm_password'>) => {
  return axiosInstance.post(`/auth/register-merchant`, data);
};

export const login = (data: LoginDataType) => {
  return axiosInstance.post(`/auth/login-merchant`, data);
};

export const updatePassword = (data: UpdatePasswordType) => {
  let user: any = localStorage.getItem('user');
  if (user) {
    user = JSON.parse(user);
  }
  return axiosInstance.patch(`/auth/update-merchant-password`, { ...data, id: user?.id });
};
