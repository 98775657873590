import CustomSnackbar from '@components/Snackbar';
import { Typography } from '@mui/material';
import Routes from '@routes/index';
import { useEffect } from 'react';
import OneSignal from 'react-onesignal';

import { getRestaurantId } from './utils';

function App() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      OneSignal.init({
        appId: 'f17670e4-5c7d-40e5-8d46-bf4b21f7b2bb',
        notifyButton: {
          enable: true
        }
      });
      OneSignal.User.addTag('pool', getRestaurantId());
    }
  }, []);

  return (
    <Typography component="div" sx={{ height: '100vh', margin: 'auto' }}>
      <Routes />;
      <CustomSnackbar />
    </Typography>
  );
}

export default App;
