import CustomButton from '@components/Button';
import Modal from '@components/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import { useCommonStore } from '@store/index';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function OrdersNotifications() {
  const setNotificationData = useCommonStore((state) => state.setNotificationData);
  const notificationData = useCommonStore((state) => state.notificationData);
  const { open, isNew, isReady, newOrders, readyOrders } = notificationData;

  const navigate = useNavigate();

  return (
    <Modal
      title={<></>}
      open={open}
      closeModal={() => {
        setNotificationData({ ...notificationData, open: false });
      }}
      hideCloseButton
      fullScreen={true}
      content={
        <Box
          sx={{
            position: 'absolute',
            inset: 0,
            bgcolor: '#fff',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <IconButton
            aria-label="close"
            onClick={() => setNotificationData({ ...notificationData, open: false })}
            sx={{
              bgcolor: '#fff',
              boxShadow: '0px 3px 10px lightgrey',
              position: 'absolute',
              right: 10,
              top: 10,
              color: '#000'
            }}>
            <CloseIcon />
          </IconButton>
          {isNew && isReady ? (
            <React.Fragment>
              {isNew && (
                <Box
                  sx={{
                    flex: 1,
                    bgcolor: 'primary.main',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Box
                    sx={{
                      width: 300,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <Box
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 9999999,
                        width: 200,
                        height: 200,
                        boxShadow: '0px 3px 10px lightgrey',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <Typography sx={{ fontSize: 100, fontWeight: 'bold' }}>
                        {isNew ? newOrders?.length : readyOrders?.length}
                      </Typography>
                      <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Orders</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ flex: 5, px: 2 }}>
                    <Typography sx={{ fontSize: 34, fontWeight: 'bold', color: '#fff' }}>
                      {'Needs to be confirmed'}
                    </Typography>
                    {/* Scroll View */}
                    <Box
                      sx={{
                        maxWidth: 'calc(100vw - 300px)',
                        overflowX: 'auto',
                        display: 'flex',
                        gap: 1,
                        py: 2
                      }}>
                      {newOrders.map((order) => (
                        <Box
                          key={order.order_id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            minWidth: '270px',
                            alignItems: 'start',
                            justifyContent: 'center'
                          }}>
                          {isReady && (
                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#fff' }}>
                              #{order?.order_id?.slice(0, 10)} for {order.user_info.first_name}{' '}
                              {order.user_info.last_name}.
                            </Typography>
                          )}
                          <CustomButton
                            sx={{
                              width: '150px',
                              bgcolor: '#fff',
                              color: '#000',
                              fontWeight: 'bold'
                            }}
                            variant="contained"
                            onClick={() => {
                              navigate(`/active-orders?order_id=${order.order_id}`);
                              setNotificationData({ ...notificationData, open: false });
                            }}>
                            View Order
                          </CustomButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
              {isReady && (
                <Box
                  sx={{
                    flex: 1,
                    bgcolor: '#F9DF00',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Box
                    sx={{
                      width: 300,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <Box
                      sx={{
                        bgcolor: '#fff',
                        borderRadius: 9999999,
                        width: 200,
                        height: 200,
                        boxShadow: '0px 3px 10px lightgrey',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <Typography sx={{ fontSize: 100, fontWeight: 'bold' }}>
                        {isNew ? newOrders?.length : readyOrders?.length}
                      </Typography>
                      <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Orders</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ flex: 5, px: 2 }}>
                    <Typography sx={{ fontSize: 34, fontWeight: 'bold', color: '#000' }}>
                      {'Has a driver arriving'}
                    </Typography>
                    <Box
                      sx={{
                        maxWidth: 'calc(100vw - 300px)',
                        overflowX: 'auto',
                        display: 'flex',
                        gap: 1,
                        py: 2
                      }}>
                      {readyOrders.map((order) => (
                        <Box
                          key={order.order_id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            minWidth: '270px',
                            alignItems: 'start',
                            justifyContent: 'center'
                          }}>
                          {isReady && (
                            <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#000' }}>
                              #{order?.order_id?.slice(0, 10)} for {order.user_info.first_name}{' '}
                              {order.user_info.last_name}.
                            </Typography>
                          )}
                          <CustomButton
                            sx={{
                              width: '150px',
                              bgcolor: '#fff',
                              color: '#000',
                              fontWeight: 'bold'
                            }}
                            variant="contained"
                            onClick={() => {
                              navigate(`/active-orders?order_id=${order.order_id}`);
                              setNotificationData({ ...notificationData, open: false });
                            }}>
                            View Order
                          </CustomButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ) : (
            <Box
              sx={{
                flex: 1,
                bgcolor: isNew ? 'primary.main' : '#F9DF00',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Box
                sx={{
                  bgcolor: '#fff',
                  borderRadius: 9999999,
                  width: 250,
                  height: 250,
                  boxShadow: '0px 3px 10px lightgrey',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography sx={{ fontSize: 100, fontWeight: 'bold' }}>
                  {isNew ? newOrders?.length : readyOrders?.length}
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Orders</Typography>
              </Box>
              <Typography sx={{ fontSize: 34, fontWeight: 'bold', color: isNew ? '#fff' : '#000' }}>
                {isNew ? 'Needs to be confirmed' : 'Has a driver arriving'}
              </Typography>
              {/* Scroll View */}
              <Box
                sx={{
                  maxWidth: '100vw',
                  overflowX: 'auto',
                  display: 'flex',
                  gap: 1,
                  py: 2,
                  px: 3
                }}>
                {(isNew ? newOrders : readyOrders).map((order) => (
                  <Box
                    key={order.order_id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      minWidth: '270px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold', color: '#000' }}>
                      #{order?.order_id?.slice(0, 10)} for {order.user_info.first_name}{' '}
                      {order.user_info.last_name}.
                    </Typography>

                    <CustomButton
                      sx={{ width: '150px', bgcolor: '#fff', color: '#000', fontWeight: 'bold' }}
                      variant="contained"
                      onClick={() => {
                        navigate(`/active-orders?order_id=${order.order_id}`);
                        setNotificationData({ ...notificationData, open: false });
                      }}>
                      View Order
                    </CustomButton>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      }
    />
  );
}
