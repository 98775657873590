import CustomButton from '@components/Button';
import Header from '@components/Header';
import Loader from '@components/Loader';
import { Box, Grid2, Typography } from '@mui/material';
import { getRestaurantOrders, updateOrder } from '@services/order';
import { updateRestaurant } from '@services/restaurant';
import { useCommonStore, useRestaurantStore } from '@store/index';
import { getRestaurantId } from '@utils/index';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Order, ResponseDataType } from 'types/services';

import ActiveOrderDetails from './ActiveOrderDetails';
import InProgressOrderDetails from './InProgressOrderDetails';
import IssueWithOrderModal from './IssueWithOrderModal';
import KitchenStatusesModal from './KitchenStatusesModal';
import MakeOutOfStockModal from './makeOutOfStock/MakeOutOfStockModal';
import NoOrder from './NoOrder';
import OrderCard from './OrderCard';
import PreTimeModal from './PrepTimeModal';
import ReadyOrderDetails from './ReadyOrderDetails';

export type KitchenStatus = {
  title: string;
  description: string;
  color: string;
};

export type OrderItemType = {
  name: string;
  quantity: number;
  seletedMenuName: string;
  price: number;
};

export type OrderType = {
  id: string;
  orderNumber: string;
  status: string;
  prepTime?: string;
  orderTime: string;
  deliveryDate: string;
  customerName: string;
  restaurantName: string;
  orderNotes?: string;
  items: OrderItemType[];
};

export type IssueWithOrderActionType = {
  title: string;
  action: () => void;
};

export default function ActiveOrders() {
  const navigate = useNavigate();
  const location = useLocation();
  const restaurant = useRestaurantStore((state) => state.restaurant);
  const addRestaurant = useRestaurantStore((state) => state.addRestaurant);
  const orderStatuses = useCommonStore((state) => state.orderStatuses);
  const [orderingStatus, setOrderingStatus] = useState<string>(
    restaurant?.status?.toLowerCase() || ''
  );

  const [showKitchenStatusModal, setshowKitchenStatusModal] = useState<boolean>(false);

  const [showPrepTimeModal, setshowPrepTimeModal] = useState<boolean>(false);
  const [prepTime, setPrepTime] = useState<number>(15);

  const [showIssueWithOrderModal, setshowIssueWithOrderModal] = useState<boolean>(false);
  const setSelectedNavItem = useCommonStore((state) => state.setSelectedNavItem);
  const [loading, setLoading] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  const kitchenStatuses: KitchenStatus[] = [
    {
      title: 'accepting',
      description: 'Business as usual',
      color: 'green'
    },
    {
      title: 'busy',
      description: 'Need more prep time',
      color: 'orange'
    },

    {
      title: 'paused',
      description: 'Pause now orders',
      color: 'red'
    }
  ];

  const [showMakeSomethingOutOfStockModal, setshowMakeSomethingOutOfStockModal] =
    useState<boolean>(false);

  const issueWithOrderActions: IssueWithOrderActionType[] = [
    {
      title: 'Adjust Prep Time',
      action: () => {
        setshowIssueWithOrderModal(false);
        setshowPrepTimeModal(true);
      }
    },
    {
      title: 'Make Something as out of Stock',
      action: () => {
        setshowIssueWithOrderModal(false);
        setshowMakeSomethingOutOfStockModal(true);
      }
    },
    {
      title: 'Support Live Chat',
      action: () => {
        setshowIssueWithOrderModal(false);
        navigate('/help-support');
      }
    },
    {
      title: 'Cancel Order',
      action: () => cancelOrder()
    }
  ];

  const cancelOrder = () => {
    const statusId = orderStatuses.find((o) => o.name === 'CANCELED')?.id;
    const updatedOrder: any = {
      ...selectedOrder,
      status: 'CANCELED'
    };
    setUpdating(true);
    updateOrder({
      id: selectedOrder?.order_id || '',
      status_id: statusId
    })
      .then((res) => {
        if (res.data.message === 'Order updated successfully') {
          setSelectedOrder(updatedOrder);
          setOrders(
            orders?.map((order) => {
              if (order.order_id === updatedOrder.order_id) {
                return updatedOrder;
              }
              return order;
            })
          );
          setshowIssueWithOrderModal(false);
        }
      })
      .finally(() => setUpdating(false));
  };

  const ConfirmPrepTime = (defaultPrepTime?: number) => {
    const statusId = orderStatuses.find((o) => o.name === 'PREPARING')?.id;
    const updatedOrder: any = {
      ...selectedOrder,
      status: 'PREPARING',
      prep_time: defaultPrepTime || prepTime || undefined
    };
    setUpdating(true);
    updateOrder({
      id: selectedOrder?.order_id || '',
      prep_time: updatedOrder.prep_time,
      status_id: statusId
    })
      .then((res) => {
        if (res.data.message === 'Order updated successfully') {
          setSelectedOrder(updatedOrder);
          setOrders(
            orders?.map((order) => {
              if (order.order_id === updatedOrder.order_id) {
                return updatedOrder;
              }
              return order;
            })
          );
          setshowPrepTimeModal(false);
        }
      })
      .finally(() => setUpdating(false));
  };

  const MarkReadyToPickup = () => {
    const statusId = orderStatuses.find((s) => s.name === 'READY FOR PICKUP')?.id;

    const updatedOrder: any = {
      ...selectedOrder,
      status: 'READY FOR PICKUP',
      prep_time: 0
    };
    setUpdating(true);
    updateOrder({
      id: selectedOrder?.order_id || '',
      prep_time: updatedOrder.prep_time,
      status_id: statusId
    })
      .then((res) => {
        if (res.data.message === 'Order updated successfully') {
          setSelectedOrder(updatedOrder);
          setOrders(
            orders?.map((order) => {
              if (order.order_id === updatedOrder.order_id) {
                return updatedOrder;
              }
              return order;
            })
          );
        }
      })
      .finally(() => setUpdating(false));
  };

  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    const price: any = selectedOrder?.items?.reduce((acc, item) => {
      return acc + item.price;
    }, 0);
    setTotalPrice(price);
  }, [selectedOrder]);

  const onClickOrderHistoryBtn = () => {
    setSelectedNavItem({ title: 'Order History' });
    navigate('/order-history');
  };

  useEffect(() => {
    const restaurantId = getRestaurantId();
    if (restaurantId) {
      setLoading(true);
      getRestaurantOrders(restaurantId)
        .then((res) => {
          const orderRes: ResponseDataType<Order[]> = res.data;
          if (orderRes.success) {
            const items = orderRes.items;
            setOrders(items);

            // Get order_id from URL params
            const searchParams = new URLSearchParams(location.search);
            const orderIdFromParams = searchParams.get('order_id');

            // If order_id is in params, find and select that order
            if (orderIdFromParams) {
              const orderFromParams = items.find((order) => order.order_id === orderIdFromParams);
              if (orderFromParams) {
                setSelectedOrder(orderFromParams);
              } else {
                setSelectedOrder(items[0]);
              }
            } else {
              setSelectedOrder(items[0]);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location.search, orderStatuses]);

  const onChangeStatus = (status: string) => {
    const s = status.toLowerCase();
    if (s != orderingStatus) {
      setshowKitchenStatusModal(false);
      setLoading(true);
      updateRestaurant({
        id: getRestaurantId(),
        status: s
      })
        .then((res) => {
          if (res.data.success) {
            setOrderingStatus(status);
            if (restaurant) {
              addRestaurant({ ...restaurant, status: s });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onClickPickOrder = () => {
    const statusId = orderStatuses.find((s) => s.name === 'OUT FOR DELIVERY')?.id;

    const updatedOrder: any = {
      ...selectedOrder,
      status: 'OUT FOR DELIVERY'
    };
    setUpdating(true);
    updateOrder({
      id: selectedOrder?.order_id || '',
      status_id: statusId
    })
      .then((res) => {
        if (res.data.message === 'Order updated successfully') {
          setSelectedOrder(updatedOrder);
          setOrders(
            orders?.map((order) => {
              if (order.order_id === updatedOrder.order_id) {
                return updatedOrder;
              }
              return order;
            })
          );
        }
      })
      .finally(() => setUpdating(false));
  };

  return (
    <>
      <Grid2 container spacing={0} sx={{ height: '100vh' }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid2 size={4}>
              <Header
                bgColor={
                  (orderingStatus === 'paused' && 'red') ||
                  (orderingStatus === 'busy' && 'orange') ||
                  'transparent'
                }>
                <Typography
                  component="div"
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}>
                  <Typography variant="h6" fontWeight={'bold'}>
                    Orders
                  </Typography>

                  <CustomButton
                    sx={{
                      py: '6px',
                      px: 1,
                      borderRadius: '4px',
                      border: '1px solid lightgrey',
                      mr: 2,
                      bgcolor: '#fff'
                    }}
                    onClick={() => {
                      setshowKitchenStatusModal(true);
                    }}>
                    <Typography
                      sx={{
                        borderLeft: `5px solid ${
                          (orderingStatus === 'accepting' && 'green') ||
                          (orderingStatus === 'busy' && 'orange') ||
                          (orderingStatus === 'paused' && 'red') ||
                          '#fff'
                        }`,
                        paddingInline: 1.5,
                        fontSize: 12,
                        color: '#000'
                      }}>
                      {orderingStatus}
                    </Typography>
                  </CustomButton>
                </Typography>
              </Header>
              <Box width={'100%'} sx={{ borderBottom: '4px solid lightgrey' }}></Box>
              {orders.length === 0 && (
                <Grid2
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1
                  }}
                  size={12}
                  container>
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      mt: 8
                    }}>
                    Currently no new orders
                  </Typography>
                </Grid2>
              )}
              <Box
                sx={{
                  overflow: 'auto',
                  height: 'calc(100vh - 65px)',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  py: 3,
                  gap: 2,
                  mb: 1,
                  pb: 5
                }}>
                {/* New Orders */}
                {orders.filter((order) => order.status === 'PENDING' || order.status === 'NEW')
                  .length > 0 && (
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'left',
                        ml: 2
                      }}>
                      NEED ACTIONS
                    </Typography>

                    {orders
                      ?.filter((order) => order.status === 'PENDING' || order.status === 'NEW')
                      .map((order, i) => (
                        <OrderCard
                          selectedOrder={selectedOrder}
                          order={order}
                          key={i}
                          onOrderClick={() => setSelectedOrder(order)}
                        />
                      ))}
                  </Box>
                )}

                {orders.filter(
                  (order) => order.status === 'PREPARING' || order.status === 'READY FOR PICKUP'
                ).length > 0 && (
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'left',
                        ml: 2
                      }}>
                      IN THE KITCHEN
                    </Typography>

                    {orders
                      ?.filter(
                        (order) =>
                          order.status === 'PREPARING' || order.status === 'READY FOR PICKUP'
                      )
                      .map((order, i) => (
                        <OrderCard
                          selectedOrder={selectedOrder}
                          order={order}
                          key={i}
                          onOrderClick={() => setSelectedOrder(order)}
                        />
                      ))}
                  </Box>
                )}

                {orders.filter((order) => order.status === 'OUT FOR DELIVERY').length > 0 && (
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'left',
                        ml: 2
                      }}>
                      OUT FOR DELIVERY
                    </Typography>

                    {orders
                      ?.filter((order) => order.status === 'OUT FOR DELIVERY')
                      .map((order, i) => (
                        <OrderCard
                          selectedOrder={selectedOrder}
                          order={order}
                          key={i}
                          onOrderClick={() => setSelectedOrder(order)}
                        />
                      ))}
                  </Box>
                )}

                <CustomButton
                  variant="contained"
                  sx={{
                    background: 'lightgrey',
                    borderRadius: '99999px',
                    color: '#000',
                    fontWeight: 'bold',
                    paddingInline: 5,
                    mx: 'auto',
                    mt: 3
                  }}
                  onClick={onClickOrderHistoryBtn}>
                  See History
                </CustomButton>
              </Box>
            </Grid2>

            {/* No Orders */}
            {Boolean(!selectedOrder?.order_id) && (
              <Grid2 size={8} bgcolor={'#fff'}>
                <NoOrder />
              </Grid2>
            )}

            {/* Active Order Details */}
            {(selectedOrder?.status === 'PENDING' || selectedOrder?.status === 'NEW') && (
              <ActiveOrderDetails
                selectedOrder={selectedOrder}
                setshowIssueWithOrderModal={setshowIssueWithOrderModal}
                setshowPrepTimeModal={setshowPrepTimeModal}
                ConfirmPrepTime={ConfirmPrepTime}
                loading={updating}
              />
            )}

            {/* In Progress Orders */}
            {(selectedOrder?.status === 'PREPARING' ||
              selectedOrder?.status === 'OUT FOR DELIVERY') && (
              <InProgressOrderDetails
                selectedOrder={selectedOrder}
                setshowIssueWithOrderModal={setshowIssueWithOrderModal}
                setshowPrepTimeModal={setshowPrepTimeModal}
                MarkReadyToPickup={() => {
                  MarkReadyToPickup();
                }}
                loading={updating}
              />
            )}

            {/* READY FOR PICKUP order details */}
            {selectedOrder?.status === 'READY FOR PICKUP' && (
              <ReadyOrderDetails
                selectedOrder={selectedOrder}
                setshowIssueWithOrderModal={setshowIssueWithOrderModal}
                setshowPrepTimeModal={setshowPrepTimeModal}
                onClickPickOrder={onClickPickOrder}
                loading={updating}
              />
            )}
          </>
        )}
      </Grid2>
      {/* Kitchen status modal */}
      <KitchenStatusesModal
        showKitchenStatusModal={showKitchenStatusModal}
        setshowKitchenStatusModal={setshowKitchenStatusModal}
        kitchenStatuses={kitchenStatuses}
        onChangeStatus={onChangeStatus}
        orderingStatus={orderingStatus}
      />

      {/* Prep time modal */}
      <PreTimeModal
        showPrepTimeModal={showPrepTimeModal}
        setshowPrepTimeModal={setshowPrepTimeModal}
        prepTime={prepTime}
        setPrepTime={setPrepTime}
        onConfirm={() => {
          ConfirmPrepTime();
        }}
        loading={updating}
      />
      {/* Issue with order modal */}
      {selectedOrder && (
        <IssueWithOrderModal
          showIssueWithOrderModal={showIssueWithOrderModal}
          setShowIssueWithOrderModal={setshowIssueWithOrderModal}
          issueWithOrderActions={issueWithOrderActions}
          loading={updating}
        />
      )}

      {selectedOrder && (
        <MakeOutOfStockModal
          showMakeSomethingOutOfStockModal={showMakeSomethingOutOfStockModal}
          setShowMakeSomethingOutOfStockModal={setshowMakeSomethingOutOfStockModal}
          order={selectedOrder}
        />
      )}
    </>
  );
}
