import CustomButton from '@components/Button';
import CustomTextfield from '@components/Textfield';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CircularProgress, Grid2, IconButton, Paper, Typography } from '@mui/material';
import { updatePassword } from '@services/auth';
import { useCommonStore } from '@store/index';
import { getValidations } from '@utils/index';
import { ChangeEvent, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validator from 'simple-react-validator';

export interface UpdatePasswordType {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export default function UpdatePassword() {
  const navigate = useNavigate();
  const validator = useRef(
    new Validator({
      ...getValidations(),
      element: (message: string, className: string) =>
        message.includes('confirm password') ? (
          <div className={className}>Confirm password should match new password</div>
        ) : (
          <div className={className}>{message}</div>
        )
    })
  );
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<UpdatePasswordType>({
    old_password: '',
    new_password: '',
    confirm_password: ''
  });
  const [visibility, setVisibility] = useState<{
    old_password: boolean;
    new_password: boolean;
    confirm_password: boolean;
  }>({
    old_password: false,
    new_password: false,
    confirm_password: false
  });

  const setSnackBarData = useCommonStore((state) => state.setSnackBarData);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onClickSubmit = () => {
    if (validator.current.allValid()) {
      setLoading(true);
      updatePassword(state)
        .then((res) => {
          if (res.data.success) {
            setSnackBarData({
              open: true,
              type: 'success',
              message: 'Password updated successfully, Please login again!'
            });
            setTimeout(() => {
              localStorage.clear();
              navigate('/login');
            }, 1000);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const renderIcon = (name: 'old_password' | 'new_password' | 'confirm_password') => {
    return (
      <IconButton onClick={() => setVisibility({ ...visibility, [name]: !visibility[name] })}>
        {visibility[name] ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    );
  };

  return (
    <>
      <Typography variant="h5">Update Your Password</Typography>
      <Paper className="paper">
        <Typography
          component="div"
          sx={{
            display: 'block !important'
          }}>
          <Grid2 container spacing={3}>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <CustomTextfield
                type={visibility['old_password'] ? 'text' : 'password'}
                onChange={handleChange}
                name="old_password"
                value={state.old_password}
                fullWidth
                label="Enter your old password"
                slotProps={{
                  input: {
                    endAdornment: renderIcon('old_password')
                  }
                }}
                isRequired
                validator={validator}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <CustomTextfield
                type={visibility['new_password'] ? 'text' : 'password'}
                onChange={handleChange}
                name="new_password"
                fullWidth
                value={state.new_password}
                label="Enter your new password"
                slotProps={{
                  input: {
                    endAdornment: renderIcon('new_password')
                  }
                }}
                isRequired
                validator={validator}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <CustomTextfield
                type={visibility['confirm_password'] ? 'text' : 'password'}
                onChange={handleChange}
                name="confirm_password"
                fullWidth
                value={state.confirm_password}
                label="Confirm new password"
                slotProps={{
                  input: {
                    endAdornment: renderIcon('confirm_password')
                  }
                }}
                validationString={`required|in:${state.new_password}`}
                isRequired
                validator={validator}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <CustomButton
                onClick={onClickSubmit}
                variant="contained"
                color="secondary"
                name="submit"
                disabled={loading}>
                {loading && <CircularProgress size={30} />} Submit
              </CustomButton>
            </Grid2>
          </Grid2>
        </Typography>
      </Paper>
    </>
  );
}
