import { getNewlyCreatedOrders, updateOrder } from '@services/order';
import { getRestaurantId } from '@utils/index';
import React, { useEffect } from 'react';
import { Order } from 'types/services';

export default function Notification() {
  useEffect(() => {
    // getNewlyCreatedOrders(getRestaurantId()).then((res) => {
    //   console.log(res.data);
    // });
  }, []);
  return <></>;
}
