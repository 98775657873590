import { getAllStatuses } from '@services/common';
import { getNewlyCreatedOrders, updateOrder } from '@services/order';
import { OrderStatusType } from '@store/commonSlice';
import { useCommonStore } from '@store/index';
import { getRestaurantId, getToken } from '@utils/index';
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Order, ResponseDataType } from 'types/services';

export default function ProtectedRoute() {
  const orderStatuses = useCommonStore((state) => state.orderStatuses);
  const setNotificationData = useCommonStore((state) => state.setNotificationData);
  const token = getToken();
  const setOrderStatuses = useCommonStore((state) => state.setOrderStatuses);
  useEffect(() => {
    if (token) {
      getAllStatuses().then((res) => {
        const data: ResponseDataType<OrderStatusType[]> = res.data;
        if (data.success) {
          setOrderStatuses(data.items);
        }
      });
    }
  }, [token, setOrderStatuses]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getNewOrders();
    }
    const handleNotification = () => {
      handlePlay(); // Play sound when notification is received
    };

    // Assuming you set up a service worker for push notifications
    window.addEventListener('notificationreceived', handleNotification);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('notificationreceived', handleNotification);
    };
  }, []);

  const getNewOrders = () => {
    setInterval(() => {
      getNewlyCreatedOrders(getRestaurantId()).then((res) => {
        if (res.data.success) {
          const newOrders: Order[] = res.data?.orders || [];
          if (newOrders.length > 0) {
            // TODO: readyOrders and isReady is for the one's when a driver is assigned
            setNotificationData({
              open: true,
              newOrders,
              readyOrders: [],
              isNew: true,
              isReady: false
            });
            newOrders.forEach((order: Order) => {
              updateOrder({
                id: order.order_id,
                status_id: orderStatuses.find((s) => s.name === 'NEW')?.id
              });
            });
          }
        }
      });
    }, 10000);
  };

  const handlePlay = () => {
    const audio = new Audio('/notification.wav');
    audio.play().catch((error) => {
      console.error('Error playing sound:', error);
    });
  };

  return token ? <Outlet /> : <Navigate to="/login" />;
}
